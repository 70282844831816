import React from "react"
/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Layout, Container, Header } from "../components"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div sx={{ paddingY: [40, 72] }}>
      <Header></Header>
      <Container sx={{ paddingY: 72, "> * + *": { marginTop: 24 } }}>
        <Styled.h2>NOT FOUND</Styled.h2>
        <Styled.h4>
          You just hit a route that doesn&#39;t exist... the sadness.
        </Styled.h4>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
